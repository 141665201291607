
import {
  IonPage
} from '@ionic/vue';
import { defineComponent } from 'vue';
import TheTabsBar from "@/components/TheTabsBar.vue";

export default defineComponent({
  name: 'Dashboard',
  components: {
    TheTabsBar,
    IonPage,
  },
  props: {
    tabs: Array
  }
});
